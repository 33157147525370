import { deserialize as oldDeserialize } from 'deserialize-json-api'

type TDeserializedDoc = {
  data: any
  relationships: any
  meta: any
  links: any
}

export function deserialize(json: unknown): TDeserializedDoc {
  return oldDeserialize(json, { transformKeys: 'camelCase' })
}
